import * as MIXPANEL from "./mixpanel";
import * as USER from "./user";
import * as PLAN from "./plan";
import * as CATEGORY from "./category";
import { PERMISSIONS, PAGE_ACCESS } from "./featuresAndPermissions";
import { COLOR } from "./color"

export const CONSTANTS = {
  MIXPANEL,
  USER,
  PLAN,
  CATEGORY,
  PERMISSIONS,
  PAGE_ACCESS,
  COLOR
};

export const SCROLL_REFERENCE_KEYS = {
  page: 'page',
  bomTable: 'bom-table'
}
