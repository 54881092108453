import React, { isValidElement, useCallback } from 'react'

const PopoverMenuContent = ({ items = [] }) => {
    return (
        <div className="bimmatch-menu-popover-content">
            {items.map((item) => (
                <PopoverMenuContentItem key={item.id}  {...item} />
            ))}
        </div>
    )
}

const PopoverMenuContentItem = ({ src, label, render, onClick, id, withHr, show = true, disabled, loading }) => {

    const handleClick = useCallback(() => {
        if (onClick) onClick(id);
    }, [onClick, id])

    if (show && render && isValidElement(render)) {
        return render
    }

    return (
        <>
            {show && (
                <div className={`bimmatch-menu-item ${disabled ? "bimmatch-menu-disabled" : "bimmatch-menu-enabled"}`} onClick={handleClick}>
                    {loading ? (
                        <span className="bimmatch-button-loading" />
                    ) : (
                        <>
                            {src && <img src={src} alt="menu-icon" />}
                            {label && <span>{label}</span>}
                        </>
                    )}
                </div>
            )}
            {withHr && <hr />}
        </>
    )
}

export default PopoverMenuContent