import React from "react";
import Modal from "../modal";
import { commonSuggestUsUrl } from "../../utils";

export default function ContactBimmatch({ visible = false, title, orgName, ...props }) {

  return (
    
      <Modal
        visible={visible}
        withFooter={false}
        size="medium"
        title={title || 'Your next best decision'}
        modalClass="contact-bimmatch-modal"
        {...props}
      >
        <div className="contact-bimmatch-container">
          <p className="list-title">Schedule a call to unlock all features of <br/> Bimmatch and boost your project efficiency.</p>
          
          <a className="schedule-call suggest-us" href={"https://calendly.com/cheli-w/30-min-meeting"}>Schedule a 30 min call</a>
          <div className="contact-bimmatch-footer">
            <p className="footer-text">Or email us: <a className='suggest-us' href={commonSuggestUsUrl(`Let’s have a quick demo, Bimmatch${orgName ? ` and ${orgName}`: ''}`, 'contact@bimmatch.com')}>contact@bimmatch.com</a></p>
            <img className="footer-icon" src={'/images/icon/match.svg'} alt='contact-match'/>
          </div>
        </div>
      </Modal>
  );
}


