import { gql } from "@apollo/client";

export const GET_PERMISSIONS = gql`
  query {
    getPermissions {
      organizationPlan {
        id
        pageAccess {
          id
          value
          permissions

          limits {
            id
            value
            maxLimit
          }
        }
      }
      
      userRoles {
        id
        value
        displayName
        permissions
        info
        plannerRole
        vendorRole
      }
      professions {
        id
        displayName
      }
    }
  }
`;