import { useMutation } from "@apollo/client";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import Dropzone from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { UPLOAD_FILE } from "../../graphqlQueries";
import ErrorText from "../error-text";
import LabelBox from "../label-box";
import Toast from "../toast";

function checkValidations(data, validations) {
  let isValid = true;
  let message = "";
  if (validations) {
    const { maxUploadFileSize, supportedFileTypes } = validations;
    const { size, name } = data;

    //validating file type
    if (supportedFileTypes && supportedFileTypes?.length) {
      const regex = new RegExp("[^.]+$");
      const extensionData = name.match(regex);
      const extension =
        extensionData && extensionData.length ? extensionData[0] : "";
      const isExtensionMatching = extension
        ? supportedFileTypes.includes(extension)
        : false;

      //if uploaded file type doesn't matches supported types
      if (!isExtensionMatching) {
        isValid = false;
        message = `Only ${supportedFileTypes
          .join(", ")
          .toLocaleUpperCase()} supported.`;
      }
    }

    if (isValid) {
      //validating file size
      const sizeInMiB = size / 1024 / 1024;
      if (sizeInMiB > maxUploadFileSize) {
        isValid = false;
        message = `Max size ${maxUploadFileSize}MB`;
      }
    }
  }

  return {
    isValid,
    message,
  };
}

const InputFile = ({
  id,
  required,
  label,
  errorMessage,
  isError = false,
  onRemove,
  fileName,
  removable = false,
  hideLabel = false,
  onUploadSuccess,
  onUploadError,
  onChange,
  onDragUpload,
  onChangeStatus,
  validations,
  fieldName,
  uploadId,
  isCustomRequest = false,
  defaultValue,
  showValidationMessage = false,
  accept,
  showRemoveImage,
  uploadPath,
  children,
  infoPlacement,
  info,
  fileUploadData = {},
  ...props
}) => {
  const uuid = useMemo(() => uuidv4(), []);
  const [isUploading, setIsUploading] = useState(false);
  const [toasterData, setToasterData] = useState([]);
  const [hasError, setHasError] = useState(isError);
  const [hasErrorMessage, setHasErrorMessage] = useState(errorMessage);
  const [defaultValueSetStatus, setDefaultValueSetStatus] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const inputRef = useRef(null);

  //API to upload file/image to bimmatch
  const [uploadFile] = useMutation(UPLOAD_FILE);

  //setting state of on change of error & message
  useEffect(() => {
    setHasError(isError);
  }, [isError]);

  useEffect(() => {
    setHasErrorMessage(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (!defaultValueSetStatus && defaultValue) {
      setDefaultValueSetStatus(true);
      setUploadedFiles(defaultValue);
    }
  }, [defaultValue, defaultValueSetStatus]);

  const uploadMultipleFilesData = useCallback(
    async (files) => {
      try {
        let error = {
          isValid: true,
          message: "",
        };

        if (
          validations?.maxUploadFileQuantity &&
          validations.maxUploadFileQuantity <
            files.length + uploadedFiles.length
        ) {
          error.isValid = false;
          error.message = `Maximum ${validations.maxUploadFileQuantity} files allowed`;
        }

        if (error.isValid) {
          for (let file of files) {
            const { isValid, message } = checkValidations(file, validations);
            if (!isValid) {
              error.isValid = isValid;
              error.message = message;
              break;
            }
          }
        }

        if (!error.isValid) {
          setHasError(true);
          setHasErrorMessage(error.message);
          return;
        } else {
          if (hasError) setHasError(false);
          if (hasErrorMessage) setHasErrorMessage("");
        }

        setIsUploading(true);
        onChangeStatus && onChangeStatus("uploading");

        const finalData = {
          id: uploadId,
          files: files,
          ...fileUploadData
        };
        if (uploadPath) finalData.path = uploadPath;
        const result = await uploadFile({
          variables: finalData,
        });
        onChangeStatus && onChangeStatus("success");

        const argData = result.data.uploadFile.map((data) => {
          const uploadedData = { ...data };
          if (uploadedData?.__typename) delete uploadedData.__typename;
          return uploadedData;
        });

        setUploadedFiles((uploadedFiles) => {
          const data = [...uploadedFiles, ...argData];
          onUploadSuccess && onUploadSuccess(data);
          return data;
        });
      } catch (error) {
        console.log("Exception", error);
        onUploadError && onUploadError(error);
        onChangeStatus && onChangeStatus("error");
        setHasError(true);
        setToasterData([
          {
            id: `input-file-toast-${uuid}`,
            title: "Error",
            description: label
              ? `${label} upload failed. Please try again.`
              : "Upload Failed. Please try again.",
            type: "error",
          },
        ]);
      } finally {
        setIsUploading(false);
      }
    },
    [
      validations,
      hasError,
      hasErrorMessage,
      uploadPath,
      uploadId,
      uploadFile,
      uuid,
      label,
      onChangeStatus,
      onUploadError,
      onUploadSuccess,
      uploadedFiles,
      fileUploadData
    ]
  );

  const onUpload = useCallback(
    (data) => {
      if (data && data.length) {
        uploadMultipleFilesData(data);
        const argData = fieldName ? { [fieldName]: data } : data;
        onDragUpload && onDragUpload(argData, data);
      } else {
        onDragUpload && onDragUpload(null);
      }
    },
    [onDragUpload, fieldName, uploadMultipleFilesData]
  );

  const onChangeInput = useCallback(
    (event) => {
      const files = event?.target?.files;
      if (files && files.length) {
        uploadMultipleFilesData([...files]);
        const argData = fieldName ? { [fieldName]: files } : files;
        if (isCustomRequest && onChange) onChange(argData, event);
      } else {
        if (isCustomRequest && onChange) onChange(null, event);
      }
    },
    [onChange, fieldName, isCustomRequest, uploadMultipleFilesData]
  );

  const onRemoveInput = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      onRemove && onRemove();
    },
    [onRemove]
  );

  const fieldValidation = validations?.supportedFileTypes || accept;

  const removeFile = useCallback(
    (removeFile) => {
      setUploadedFiles((files) => {
        const newFiles = files.filter((file) => file.Key !== removeFile.Key);
        onUploadSuccess && onUploadSuccess(newFiles);
        return newFiles;
      });
    },
    [onUploadSuccess]
  );

  const childrenWithProps = useMemo(
    () =>
      React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(children || <></>, {
            removeFile,
            files: uploadedFiles,
          });
        }
        return child;
      }),
    [children, removeFile, uploadedFiles]
  );

  return (
    <div
      className={`input-file ${
        !hasError ? "input-file-normal" : "input-file-error"
      } ${isUploading ? "input-file-loading" : ""}`}
    >
      {!hideLabel && (
        <LabelBox
          required={required}
          label={label}
          infoPlacement={infoPlacement}
          info={info}
        />
      )}
      <Dropzone onDrop={onUpload} multiple={true}>
        {({ getRootProps, getInputProps }) => (
          <div className="input-file-container">
            <label {...getRootProps()} htmlFor={uuid}>
              <div className="input-file-label">
                {childrenWithProps}
                {removable && !isUploading && (
                  <img
                    onClick={onRemoveInput}
                    className="remove"
                    src="/images/icon/close.svg"
                    alt="remove"
                  />
                )}
              </div>
            </label>
            <input
              {...props}
              ref={inputRef}
              onChange={onChangeInput}
              id={uuid}
              data-id={id}
              type="file"
              disabled={isUploading}
              accept={
                fieldValidation && fieldValidation?.length
                  ? "." + fieldValidation.join(",.")
                  : ""
              }
              multiple={true}
            />
          </div>
        )}
      </Dropzone>

      {showValidationMessage && <ErrorText message={hasErrorMessage} />}
      <Toast
        toastList={toasterData}
        position={"top-right"}
        autoDeleteTime={3000}
      />
    </div>
  );
};

export default InputFile;
