import React, { useCallback, useState } from 'react'
import ContactBimmatch from '../contact-bimmatch'


const UnlockPremimumButton = ({ name, src, onClick }) => {

    const [isModalVisible, setIsModalVisible] = useState(false)

    const onClickButton = useCallback(() => {
        setIsModalVisible(true)
        onClick && onClick()
    }, [onClick])

    return ( 
        <>
            <div className='unlock-premium-button' onClick={onClickButton}>
                <img src={src || "/images/icon/lock-open.svg"} alt="button-icon"/>
                <span>{name || "Unlock features and increase efficiency"}</span>
            </div>
            {isModalVisible && <ContactBimmatch visible={isModalVisible} onClose={() => setIsModalVisible(false)}/>}
        </>
        

     );
}
 
export default UnlockPremimumButton;